export default [

  {
    path: '/apps/beverages/list',
    name: 'apps-beverages-list',
    component: () => import('@/views/apps/beverages/beverages-list/BeveragesList.vue'),
    meta: {
      resource: 'Beverages',
      action: 'show',
    },
  },
  {
    path: '/apps/beverages/edit/:id/:clone',
    name: 'apps-beverages-edit',
    component: () => import('@/views/apps/beverages/beverages-edit/BeveragesEdit.vue'),
    meta: {
      resource: 'Beverages_Beverages',
      action: 'create',
    },
  },

  /*
  {
    path: '/apps/users/view/:id',
    name: 'apps-users-view',
    component: () => import('@/views/apps/user/users-view/UsersView.vue'),
    meta: {
      resource: 'menu_Users',
      action: 'read',
    },
  },
  {
    path: '/apps/users/edit/:id',
    name: 'apps-users-edit',
    component: () => import('@/views/apps/user/users-edit/UsersEdit.vue'),
    meta: {
      resource: 'menu_Users',
      action: 'read',
    },
  },

   */
  {
    path: '/apps/planograms/list',
    name: 'apps-planograms-list',
    component: () => import('@/views/apps/planograms/planograms-list/PlanogramsList.vue'),
    meta: {
      resource: 'Planograms',
      action: 'show',
    },
  },
  {
    path: '/apps/planograms/edit/:id/',
    name: 'apps-planograms-edit',
    component: () => import('@/views/apps/planograms/planograms-edit/PlanogramsEdit.vue'),
    meta: {
      resource: 'Planograms',
      action: 'create',
    },
  },

]

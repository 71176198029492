import useJwt from '@/auth/jwt/useJwt'

const webSocketsService = {}

webSocketsService.install = (Vue, options) => {
  let closed = false
  let hundler = null
  let subscribtion = {}
  let ws = new WebSocket(options.url)
  const reconnectInterval = options.reconnectInterval || 5000

  // eslint-disable-next-line no-param-reassign
  Vue.prototype.$webSocketsConnect = (subscr, hndlr) => {
    // console.log('****************** WILL CONNECT FROM PROTOTYPE')

    subscribtion = subscr
    hundler = hndlr

    ws = new WebSocket(options.url, localStorage.accessToken ? localStorage.accessToken : '')

    ws.onopen = () => {
      // Restart reconnect interval
      // reconnectInterval = options.reconnectInterval || 5000
      closed = false
      ws.send(JSON.stringify(subscribtion))
    }

    ws.onmessage = event => {
      // New message from the backend - use JSON.parse(event.data)
      // eslint-disable-next-line no-use-before-define
      // handleNotification(event)
      const data = JSON.parse(event.data)
      // console.log(event, data)
      if (data.expired) {
        useJwt.refreshToken().then(r => {
          useJwt.setToken(r.data.accessToken)
          useJwt.setRefreshToken(r.data.refreshToken)
          useJwt.onAccessTokenFetched(r.data.accessToken)
        })
          .catch(() => {
          // console.log(err)
          })
      } else {
        // hundler(data)
        hundler()
      }
    }

    ws.onclose = event => {
      if (event) {
        // Event.code 1000 is our normal close event
        // console.log(event, reconnectInterval)
        if (event.code !== 1000 && !closed) {
          const maxReconnectInterval = options.maxReconnectInterval || 20000
          setTimeout(() => {
            if (reconnectInterval < maxReconnectInterval) {
              // Reconnect interval can't be > x seconds
              // reconnectInterval += 1000
              // console.log('INCREASE reconnectInterval', reconnectInterval)
            }
            if (!closed) Vue.prototype.$webSocketsConnect(subscribtion, hundler)
          }, reconnectInterval)
        }
      }
    }

    // eslint-disable-next-line no-unused-vars
    ws.onerror = error => {
      // console.log(error)
      ws.close()
    }
  }

  // eslint-disable-next-line no-param-reassign
  Vue.prototype.$webSocketsDisconnect = () => {
    // Our custom disconnect event
    closed = true
    ws.close()
  }

  // eslint-disable-next-line no-param-reassign
  Vue.prototype.$webSocketsSend = data => {
    // Send data to the backend - use JSON.stringify(data)
    ws.send(JSON.stringify(data))
  }

  /*
    Here we write our custom functions to not make a mess in one function
  */
  /*
  function handleNotification(params) {
    console.log(params)
    options.store.dispatch('notifications/setNotifications', params.data)
  }
  */
}

export default webSocketsService

import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import i18n from '@/libs/i18n'
import webSocketService from '@/libs/ws/webSocketService'
// eslint-disable-next-line import/no-extraneous-dependencies
import DatePicker from 'vue2-datepicker'
import VueExcelXlsx from 'vue-excel-xlsx'
import router from './router'
import store from './store'
import App from './App.vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'vue2-datepicker/index.css'
// Global Components
import './global-components'
// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

Vue.use(VueExcelXlsx)
// Datepicker
Vue.use(DatePicker)
// Axios Mock Adapter
// import '@/@fake-db/db'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

Vue.use(webSocketService, {
  store,
  url: process.env.VUE_APP_WS,
})

// Feather font icon - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')

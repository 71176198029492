export default [
  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*

  {
    path: '/apps/roles/list',
    name: 'apps-roles-list',
    component: () => import('@/views/apps/roles/roles-list/RolesList.vue'),
    meta: {
      resource: 'Roles',
      action: 'show',
    },
  },
  {
    path: '/apps/roles/edit/:id',
    name: 'apps-roles-edit',
    component: () => import('@/views/apps/roles/roles-edit/RolesEdit.vue'),
    meta: {
      resource: 'Roles',
      action: 'view',
    },
  },
  {
    path: '/apps/users/list',
    name: 'apps-users-list',
    component: () => import('@/views/apps/user/users-list/UsersList.vue'),
    meta: {
      resource: 'Users',
      action: 'show',
    },
  },
  {
    path: '/apps/users/view/:id',
    name: 'apps-users-view',
    component: () => import('@/views/apps/user/users-view/UsersView.vue'),
    meta: {
      resource: 'Users',
      action: 'view',
    },
  },
  {
    path: '/apps/users/edit/:id',
    name: 'apps-users-edit',
    component: () => import('@/views/apps/user/users-edit/UsersEdit.vue'),
    meta: {
      resource: 'Users',
      action: 'view',
    },
  },
  {
    path: '/apps/companies/list',
    name: 'apps-companies-list',
    component: () => import('@/views/apps/companies/companies-list/CompaniesList.vue'),
    meta: {
      resource: 'Companies',
      action: 'show',
    },
  },
  /* * /
      {
        path: '/apps/companies/view/:id',
        name: 'apps-companies-view',
        component: () => import('@/views/apps/companies/companies-view/CompaniesView.vue'),
      },
  /* */
  {
    path: '/apps/companies/edit/:id',
    name: 'apps-companies-edit',
    component: () => import('@/views/apps/companies/companies-edit/CompaniesEdit.vue'),
    meta: {
      resource: 'Companies',
      action: 'view',
    },
  },
  {
    path: '/apps/objects/list',
    name: 'apps-objects-list',
    component: () => import('@/views/apps/objects/objects-list/ObjectsList.vue'),
    meta: {
      resource: 'Locations',
      action: 'show',
    },
  },
  /* * /
      {
        path: '/apps/objects/view/:id',
        name: 'apps-objects-view',
        component: () => import('@/views/apps/objects/objects-view/ObjectsView.vue'),
      },

   /* */
  {
    path: '/apps/objects/edit/:id',
    name: 'apps-objects-edit',
    component: () => import('@/views/apps/objects/objects-edit/ObjectsEdit.vue'),
    meta: {
      resource: 'Locations',
      action: 'view',
    },
  },
  {
    path: '/apps/machines/list',
    name: 'apps-machines-list',
    component: () => import('@/views/apps/machines/machines-list/MachinesList.vue'),
    meta: {
      resource: 'Machines',
      action: 'show',
    },
  },
  {
    path: '/apps/machines/edit/:id',
    name: 'apps-machines-edit',
    component: () => import('@/views/apps/machines/machines-edit/MachinesEdit.vue'),
    meta: {
      resource: 'Machines',
      action: 'view',
    },
  },
  {
    path: '/apps/events/list',
    name: 'apps-events-list',
    component: () => import('@/views/apps/events/events-list/EventsList.vue'),
    meta: {
      resource: 'Telemetry',
      action: 'show',
    },
  },

  {
    path: '/apps/skuds/list',
    name: 'apps-skuds-list',
    component: () => import('@/views/apps/skuds/skuds-list/SkudsList.vue'),
    meta: {
      resource: 'Skuds',
      action: 'show',
    },
  },
  {
    path: '/apps/skuds/edit/:id',
    name: 'apps-skuds-edit',
    component: () => import('@/views/apps/skuds/skuds-edit/SkudsEdit.vue'),
    meta: {
      resource: 'Skuds',
      action: 'view',
    },
  },
  {
    path: '/apps/skudhs/list',
    name: 'apps-skudhs-list',
    component: () => import('@/views/apps/skudhs/skudhs-list/SkudhsList.vue'),
    meta: {
      resource: 'Skuds',
      action: 'view',
    },
  },

  {
    path: '/apps/devices/list',
    name: 'apps-devices-list',
    component: () => import('@/views/apps/devices/devices-list/DevicesList.vue'),
    meta: {
      resource: 'Devices',
      action: 'show',
    },
  },
  {
    path: '/apps/sales/list',
    name: 'apps-sales-list',
    component: () => import('@/views/apps/sales/sales-list/SalesList.vue'),
    meta: {
      resource: 'Drinks',
      action: 'show',
    },
  },
  {
    path: '/apps/drinks/list',
    name: 'apps-drinks-list',
    component: () => import('@/views/apps/drinks/drinks-list/DrinksList.vue'),
    meta: {
      resource: 'Components',
      action: 'show',
    },
  },
  {
    path: '/apps/others/list',
    name: 'apps-others-list',
    component: () => import('@/views/apps/others/others-list/OthersList.vue'),
    meta: {
      resource: 'Others',
      action: 'show',
    },
  },

]

// import store from '@/store'
// eslint-disable-next-line
import router from '@/router'
import jwtDefaultConfig from './jwtDefaultConfig'
// import { initialAbility } from '@/libs/acl/config'

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken()

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => {
        if (response.config.url !== this.jwtConfig.refreshEndpoint) {
          this.isAlreadyFetchingAccessToken = false
        }
        // console.log('RESPONSE', response.config.url, this.isAlreadyFetchingAccessToken)
        return response
      },
      error => {
        // const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config

        // console.log('RESPONSE WITH ERROR', response.config.url)

        // if (status === 401) {
        if (response && response.status === 401) {
          // console.log('RESPONSE 401', response.config.url, this.isAlreadyFetchingAccessToken)
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
            // console.log('WILL SEND refreshToken', response.config.url, this.isAlreadyFetchingAccessToken, this.getRefreshToken())
            this.refreshToken().then(r => {
              this.isAlreadyFetchingAccessToken = false

              // console.log('refreshToken', response.config.url, this.isAlreadyFetchingAccessToken)

              // Update accessToken in localStorage
              this.setToken(r.data.accessToken)
              this.setRefreshToken(r.data.refreshToken)

              this.onAccessTokenFetched(r.data.accessToken)
            })
              .catch(() => {
                // console.log(err)
              })
          // } else if (this.isAlreadyReceivedAccessToken) {
          } else if (this.isAlreadyFetchingAccessToken && response.config.url === this.jwtConfig.refreshEndpoint) {
            // console.log(response)
            /* */
            // Remove userData from localStorage
            // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
            localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
            localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)

            // Remove userData from localStorage
            localStorage.removeItem('userData')

            // Reset ability
            // Vue.$ability.update(initialAbility)

            // Redirect to login page
            if (this.isAlreadyFetchingAccessToken) router.push({ name: 'auth-login' })
            /* */
            this.isAlreadyFetchingAccessToken = false
          }
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // Make sure to assign accessToken according to your response.
              // Check: https://pixinvent.ticksy.com/ticket/2413870
              // Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              if (response.config.url === this.jwtConfig.refreshEndpoint) resolve()
              else resolve(this.axiosIns(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        return Promise.reject(error)
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    // console.log(this.getRefreshToken())
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken(),
    })
  }
}
